.btn:disabled {
  background: $btn__color__syn__disabled-background!important;
  color: $color__syntellis-neutral-03!important;
  border-radius: 6px!important;
}

// Rotate caret.
.dropdown-toggle[aria-expanded="true"]:after {
  transform: rotate(180deg);
}

.dropdown-toggle:after {
  transition: 0.5s;
}

.btn-primary {
  color: $color_white;
  background: $btn__color__syn__default-background;
  border: 3px solid $btn__color__syn__default-background!important;
  border-radius: 6px!important;

  &:focus {
    background: $btn__color__syn__focus-background!important;
    border: 3px solid $color__syntellis-action-06!important;
    color: $color_white!important;
  }

  &:hover {
    background: $btn__color__syn__hover-background!important;
    color: $color_white!important;
  }
  &:disabled {
    background: $btn__color__syn__disabled-background!important;
    color: $color__syntellis-neutral-03!important;
  }
}

.btn-secondary {
  color: $color__syntellis-action-02;
  border: 3px solid $btn__color__syn__sec_default-background!important;
  background: $btn__color__syn__sec_default-background;
  border-radius: 6px!important;

  &:focus {
    background: $btn__color__syn__sec_focus-background!important;
    border: 3px solid $color__syntellis-action-03!important;
  }

  &:hover {
    background: $btn__color__syn__sec_hover-background!important;
    text-decoration: none!important;
  }

  &:disabled {
    background: $btn__color__syn__disabled-background!important;
    color: $color__syntellis-neutral-03!important;
  }
}

.btn-danger {
  color: $color__syntellis-neutral-01;
  background: $btn__color__syn__warn_default-background;
  border: 1px solid $color__syntellis-warning-02;
  border-radius: 6px!important;

  &:focus {
    background: $btn__color__syn__warn_focus-background!important;
    color: $color__syntellis-neutral-01!important;
  }

  &:hover {
    background: $btn__color__syn__warn_hover-background!important;
    text-decoration: none!important;
    color: $color__syntellis-action-02!important;
  }

  &:disabled {
    background: $btn__color__syn__disabled-background!important;
    color: $color__syntellis-neutral-03!important;
  }
}

.btn-secondary-syn {
  color: $color__syntellis-action-02;
  background: $btn__color__syn__sec_default-background;
  border: 3px solid $btn__color__syn__sec_default-background!important;
  border-radius: 6px!important;

  &:focus {
    background: $btn__color__syn__sec_focus-background!important;
    border: 3px solid $color__syntellis-action-03!important;
  }

  &:hover {
    background: $btn__color__syn__sec_hover-background!important;
    text-decoration: none!important;
  }

  &:disabled {
    background: $btn__color__syn__disabled-background!important;
    color: $color__syntellis-neutral-03!important;
  }
}