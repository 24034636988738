// ********************
// Colors
// ********************

$color-primary: #456d9e;
$color-secondary: #53bde3;
$color-tertiary: #d0dceb;
$color-white: #fff;
$color-gray: #c7cbcf;
$color-gray-light: #eaeaea;
$color-green: #88b540;

// Syntellis Colors
$color__syntellis-action-01: #10528e;
$color__syntellis-action-02: #0a3e6d;
$color__syntellis-action-03: #032849;
$color__syntellis-action-05: #dbedff;
$color__syntellis-action-06: #cce5ff;
$color__syntellis-action-07: #b7daff;
$color__syntellis-neutral-01: #343a3f;
$color__syntellis-neutral-03: #a2a9b0;
$color__syntellis-neutral-04: #dde1e6;
$color__syntellis-warning-01: #f1797a;
$color__syntellis-warning-02: #ffafb0;
$color__syntellis-warning-03: #ffcece;
$color__syntellis-warning-04: #ffeaea;

// ********************
// Colors from Syntellis Style Guide
// ********************
$neutral-04: #dde1e6;

// ********************
// Colors for specific elements
// ********************

$color-bg: #f8fafc;
$color-link-text: #2581c6;

// Syntellis primary button colors
$btn__color__syn__default-background: $color__syntellis-action-01;
$btn__color__syn__hover-background: $color__syntellis-action-02;
$btn__color__syn__focus-background: $color__syntellis-action-01;
$btn__color__syn__active-background: $color__syntellis-action-03;
$btn__color__syn__disabled-background: $color__syntellis-neutral-04;

// Syntellis secondary button colors
$btn__color__syn__sec_default-background: $color__syntellis-action-05;
$btn__color__syn__sec_hover-background: $color__syntellis-action-06;
$btn__color__syn__sec_focus-background: $color__syntellis-action-05;
$btn__color__syn__sec_active-background: $color__syntellis-action-07;

// Syntellis warning button colors
$btn__color__syn__warn_default-background: $color__syntellis-warning-04;
$btn__color__syn__warn_hover-background: $color__syntellis-warning-03;
$btn__color__syn__warn_focus-background: $color__syntellis-warning-04;
$btn__color__syn__warn_active-background: $color__syntellis-warning-02;
