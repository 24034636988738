.login-header-image {
  max-width: 360px;
  // height: 68px;
  /* Inside auto layout */
  flex: none;
  order: 0;
}

.login-header-text {
  font-family: 'Roboto', sans-serif;
  font-size: 28px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0px;
  text-align: center;
  color: #FFFFFF
}

.login-subheader-text{
  font-family: 'Roboto', sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0px;
  text-align: center;
  color: #FFFFFF
}

.login-card-header {
  font-family: 'Roboto', sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0px;
  text-align: left;
  background: transparent;
  color: #343A3F;
}

.form-label {
  font-weight: bold;
}

